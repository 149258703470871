import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractInfoEnum } from '../../shared/models/app/abstract-info-enum';
import { AppInfo } from '../../shared/models/app/app-info';
import { InfoCategory } from '../../shared/models/app/info-category';
import InfoCoupon from '../../shared/models/coupons/info-coupon';
import { OrderStatus } from '../../shared/models/order/order';
import InfoSubCategory from '../../shared/models/product/info-sub-category';
import Report from '../../shared/models/reports/report';
import { ArrayUtils } from '../../shared/utils/array-utils';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  public completeAppInfo$ = new BehaviorSubject<boolean>(false);

  private globalAppInfo?: AppInfo;
  private globalCategoriesWithProductActive: InfoCategory[] = [];

  constructor(private httpService: HttpService) {}

  public async getCategories(): Promise<Array<InfoCategory>> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.categories || [];
  }

  public async getCategoriesWithProductActive(): Promise<InfoCategory[]> {
    if (this.globalCategoriesWithProductActive?.length > 0) {
      return this.globalCategoriesWithProductActive;
    }
    const app = this.getGlobalAppInfo();
    const categoriesWithProductActive = app?.enumeretions?.categories?.filter(
      (cat) => cat.hasActiveProduct
    );
    categoriesWithProductActive?.forEach((cat) => {
      cat.subCategories = cat.subCategories?.filter(
        (sub) => sub.hasActiveProduct
      );

      cat.subCategories?.forEach((subNew) => {
        subNew.actuationSides = subNew.actuationSides?.filter(
          (as) => as.hasActiveProduct
        );
        subNew.actuationTypes = subNew.actuationTypes?.filter(
          (at) => at.hasActiveProduct
        );
        subNew.aluminumWallThickness = subNew.aluminumWallThickness?.filter(
          (at) => at.hasActiveProduct
        );
        subNew.brands = subNew.brands?.filter((b) => b.hasActiveProduct);
        subNew.capabilities = subNew.capabilities?.filter(
          (cap) => cap.hasActiveProduct
        );
        subNew.colors = subNew.colors?.filter(
          (color) => color.hasActiveProduct
        );
        subNew.colorsCode = subNew.colorsCode?.filter(
          (cc) => cc.hasActiveProduct
        );
        subNew.fabricCompositions = subNew.fabricCompositions?.filter(
          (fab) => fab.hasActiveProduct
        );
        subNew.finishes = subNew.finishes?.filter(
          (fin) => fin.hasActiveProduct
        );
        subNew.heightThickness = subNew.heightThickness?.filter(
          (ht) => ht.hasActiveProduct
        );
        subNew.lengths = subNew.lengths?.filter((len) => len.hasActiveProduct);
        subNew.models = subNew.models?.filter(
          (model) => model.hasActiveProduct
        );
        subNew.potencies = subNew.potencies?.filter(
          (pot) => pot.hasActiveProduct
        );
        subNew.speeds = subNew.speeds?.filter(
          (speed) => speed.hasActiveProduct
        );
        subNew.unitsCommercialize = subNew.unitsCommercialize?.filter(
          (uni) => uni.hasActiveProduct
        );
        subNew.voltages = subNew.voltages?.filter(
          (voltage) => voltage.hasActiveProduct
        );
        subNew.widths = subNew.widths?.filter(
          (width) => width.hasActiveProduct
        );
      });
    });

    this.globalCategoriesWithProductActive = categoriesWithProductActive || [];
    return this.globalCategoriesWithProductActive;
  }

  public async getOrderStatus(): Promise<AbstractInfoEnum[]> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.orderStatus || [];
  }

  public async getOrderStatusToTimeLine(): Promise<AbstractInfoEnum[]> {
    let statuses = await this.getOrderStatus();
    statuses = statuses.filter((status) => status.id !== OrderStatus.CANCELED);
    statuses.forEach((status) => {
      if (status.id === OrderStatus.CONCLUDED) {
        status.description = 'Entregue';
      }
    });
    return statuses || [];
  }

  public async getMarketplaces(): Promise<AbstractInfoEnum[]> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.marketplaces || [];
  }

  public async getGroupingAttributes(): Promise<AbstractInfoEnum[]> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.groupingAttributes || [];
  }

  public async getProductOrigin(): Promise<AbstractInfoEnum[]> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.productOrigins || [];
  }

  public async getReports(): Promise<Report | undefined> {
    const app = this.getGlobalAppInfo();
    return app?.reports;
  }

  public async getInfoCoupon(): Promise<InfoCoupon | undefined> {
    const app = this.getGlobalAppInfo();
    return app?.enumeretions?.coupon;
  }

  public async getAllSubCategories(): Promise<InfoSubCategory> {
    const app = await this.getGlobalAppInfo();
    const newSubs: InfoSubCategory = new InfoSubCategory();
    app?.enumeretions?.categories?.forEach((cat) => {
      cat?.subCategories?.forEach((subs) => {
        subs.heightThickness.forEach((thicknes) => {
          if (
            !newSubs.heightThickness.find((item) => item.id === thicknes.id)
          ) {
            newSubs.heightThickness.push(thicknes);
          }
        });
        subs.widths.forEach((width) => {
          if (!newSubs.widths.find((item) => item.id === width.id)) {
            newSubs.widths.push(width);
          }
        });
        subs.lengths.forEach((length) => {
          if (!newSubs.lengths.find((item) => item.id === length.id)) {
            newSubs.lengths.push(length);
          }
        });
        subs.colors.forEach((color) => {
          if (!newSubs.colors.find((c) => c.id === color.id)) {
            newSubs.colors.push(color);
          }
        });
      });
    });
    ArrayUtils.sort(newSubs.heightThickness, 'description');
    ArrayUtils.sort(newSubs.widths, 'description');
    ArrayUtils.sort(newSubs.lengths, 'description');
    ArrayUtils.sort(newSubs.colors, 'description');
    return newSubs;
  }

  setCompleteAppInfo(value: boolean) {
    this.completeAppInfo$.next(value);
  }

  getCompleteAppInfo(): Observable<any> {
    return this.completeAppInfo$.asObservable();
  }

  loadData(): Observable<any> {
    return this.httpService.get('app-info');
  }

  setGlobalAppInfo(data: AppInfo) {
    const appInfo: AppInfo = new AppInfo();
    appInfo.fromJson(data);
    this.globalAppInfo = appInfo;
    this.setCompleteAppInfo(true);
  }

  getGlobalAppInfo() {
    return this.globalAppInfo;
  }

  getCarrierDescription(carrier: string) {
    const carriers: AbstractInfoEnum[] =
      this.getGlobalAppInfo()?.enumeretions?.carriers || [];
    const objCarrier = carriers.find((c) => c.id === carrier);
    return objCarrier?.description || carrier;
  }
}
