import { Cart } from '../cart/cart';
import Address from '../user/address';
import User from '../user/user';

export default class Budget {
  address?: Address; // Endereço do orçamento (GET e POST)
  carrier?: string; // Transportadora
  carrierDescription?: string; // Descrição da Transportadora
  cart?: Cart; // Var receber o carrinho com os dados de desocnto, frete e etc
  createdAt?: string;
  creatorUser?: User; // Vendedor que criou o orçamento (pegar usuário logado)
  deliveryTime?: number; // Prazo da entrega
  discountSeller?: number; // Desconto vendedor
  hash?: string;
  id?: number;
  observations?: string; // Observações do orçamento
  status?: string;
  user?: User; // GET e POST - se tiver id, é um User(), se não tiver, poder ser userLeadId ou como é hoje
  valueFreight?: number; // Valor do frete
  volume?: number; // Volume do frete
}
