import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoCategory } from '../../shared/models/app/info-category';
import { BlindsOptionsEnum } from '../../shared/models/app/info-enum';
import MarketplaceTransaction from '../../shared/models/app/marketplace-transaction';
import { CartItem } from '../../shared/models/cart/cart';
import Freight from '../../shared/models/freight/freight';
import Order, {
  OrderEvent,
  orderStatus,
  OrderStatus,
} from '../../shared/models/order/order';
import OrderBuyer from '../../shared/models/order/order-buyer';
import OrderCancel from '../../shared/models/order/order-cancel';
import OrderFilter from '../../shared/models/order/order-filter';
import OrderGrid from '../../shared/models/order/order-grid';
import OrderNFE from '../../shared/models/order/order-nfe';
import OrderShipping from '../../shared/models/order/order-shipping';
import OrderTracking from '../../shared/models/order/order-tracking';
import OrderTransaction from '../../shared/models/order/order-transaction';
import { Product } from '../../shared/models/product/product';
import { AppInfoService } from '../app-info/app-info.service';
import { HttpService } from '../http/http.service';
import { GoogleAnalyticsService } from './../google/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private appInfoService: AppInfoService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private httpService: HttpService
  ) {}

  public async updateTagmanager(orders: Array<OrderGrid>): Promise<void> {
    const filteredOrders = orders.filter(
      (order) =>
        (order.currentStatus === OrderStatus.INVOICED ||
          order.currentStatus === OrderStatus.PAID_WAITING_SHIP ||
          order.currentStatus === OrderStatus.PAID_WAITING_DELIVERY) &&
        order?.marketPlace === 'RM_POLICARBONATOS' &&
        order?.sentToTagmanager === false
    );

    const ids = filteredOrders.map((order) => order.id);
    if (ids.length === 0) {
      return;
    }

    try {
      const orderPromises = ids.map((id) => this.getOrder(id || 0));
      const orders = await Promise.all(orderPromises);

      orders.forEach((order) => {
        this.googleAnalyticsService.sendEventPurcharse(order);
      });

      await Promise.all(
        ids.map((id) =>
          this.httpService.put(`order/${id}/tagmanager/`, null).toPromise()
        )
      );

      console.log('Todos os pedidos foram atualizados com sucesso.');
    } catch (error: any) {
      const msg =
        error?.error?.message ||
        error?.error?.error ||
        'Ocorreu um erro ao alterar tagmanager!';
      console.error(msg);
    }
  }

  getOrders(
    filter: OrderFilter,
    updateTagmanager: boolean
  ): Promise<Array<OrderGrid>> {
    const headers = new HttpHeaders({
      'no-loader': 'no-loader',
    });
    let orders: Array<OrderGrid> = [];
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`order/pageable/grid/list`, filter, headers)
        .subscribe({
          next: async (res: any) => {
            if (res?.content) {
              res.content.map((item: any) => {
                item.estimateDate = item?.estimateDate?.substring(0, 10);
                orders.push(Object.assign(new OrderGrid(), item));
              });
              if (updateTagmanager) {
                this.updateTagmanager(orders);
              }
              resolve(orders);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os seus pedidos.';
            reject(msg);
          },
        });
    });
  }

  getOrder(id: number): Promise<Order> {
    const headers = new HttpHeaders({
      'no-loader': 'no-loader',
    });
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`order/${id}/`, {} as HttpParams, headers)
        .subscribe({
          next: async (res: any) => {
            if (res) {
              const order: Order = await this.wsToOrder(res);
              resolve(order);
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao buscar os dados do seu pedidos.';
            reject(msg);
          },
        });
    });
  }

  async wsToOrder(item: any): Promise<Order> {
    const categories: InfoCategory[] =
      await this.appInfoService.getCategories();
    const order: Order = {
      ...item,
      orderHistories: item.orderHistories,
      statusCanceled: this.getItemStatusCanceled(item.orderHistories),
      orderTransaction: item.orderTransaction
        ? Object.assign(new OrderTransaction(), item.orderTransaction)
        : null,
      orderNFE: item.orderNFE
        ? Object.assign(new OrderNFE(), item.orderNFE)
        : null,
      marketplaceTransaction: item.marketplaceTransaction
        ? Object.assign(
            new MarketplaceTransaction(),
            item.marketplaceTransaction
          )
        : null,
      orderTracking: item.orderTracking
        ? Object.assign(new OrderTracking(), item.orderTracking)
        : null,
      orderCanceled: item.orderCanceled
        ? Object.assign(new OrderCancel(), item.orderCanceled)
        : null,
      orderBuyer: item.orderBuyer
        ? Object.assign(new OrderBuyer(), item.orderBuyer)
        : null,
      orderShipping: item.orderShipping
        ? Object.assign(new OrderShipping(), item.orderShipping)
        : null,
      cartProducts: item.cartProducts.map((itemCart: any) => {
        let product: Product;
        product = new Product();
        product.fromJson(itemCart.product, categories);
        const cartItem: CartItem = {
          product,
          amount: itemCart.amount,
          idProduct: product.id,
          id: itemCart.id,
          value: itemCart.value,
          discount: itemCart.discount,
          total: itemCart.total,
          customWidth: itemCart?.customWidth,
          customHeight: itemCart?.customHeight,
          controlSide: itemCart?.controlSide,
          controlSideDescription:
            itemCart?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_LEFT
              ? 'Esquerdo'
              : itemCart?.controlSide === BlindsOptionsEnum.CONTROL_SIDE_RIGHT
              ? 'Direito'
              : '',
          drive: itemCart?.drive,
          driveDescription:
            itemCart?.drive === BlindsOptionsEnum.DRIVE_MOTOR
              ? 'Motorizado'
              : itemCart?.drive === BlindsOptionsEnum.DRIVE_MANUAL
              ? 'Manual'
              : '',
          panel: itemCart?.panel,
          panelDescription:
            itemCart?.panel === BlindsOptionsEnum.WITH_PANEL
              ? 'Com Bandô'
              : itemCart?.panel === BlindsOptionsEnum.WITHOUT_PANEL
              ? 'Sem Bandô'
              : '',
          typeFixing: itemCart?.typeFixing,
          typeFixingDescription:
            itemCart?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_WALL
              ? 'Na parede'
              : itemCart?.typeFixing === BlindsOptionsEnum.TYPE_FIXING_CEILING
              ? 'No teto'
              : '',
        };
        return cartItem;
      }),
    };
    await this.formatOrder(order);
    return order;
  }

  getItemStatusCanceled(orderHistories: Array<OrderEvent>): string {
    const status: OrderEvent | undefined = orderHistories?.find(
      (s) => s.updated === 'CANCELED'
    );
    return status?.createdAt || '';
  }

  private formatOrder(order: Order) {
    if (order?.orderCanceled) {
      order.administratorNotes = `Motivo do cancelamento: ${
        order.orderCanceled?.cancelDetails
      }\nData do Cancelamento: ${order?.orderCanceled?.cancelDate}
      \n${order?.administratorNotes || ''}`;
    }
    this.getDtEstimate(order);
  }

  private getDtEstimate(order: Order) {
    if (order?.orderTracking) {
      if (order?.orderTracking?.estimateDate) {
        order.dtEstimate = order?.orderTracking?.estimateDate?.substring(0, 10);
      }
    } else {
      if (order?.orderShipping) {
        if (order?.orderShipping?.optionFreight === Freight.STORE_PICKUP) {
          if (order?.orderShipping?.dateStorePickup) {
            order.dtEstimate = order?.orderShipping?.dateStorePickup?.substring(
              0,
              10
            );
          }
        }
      }
    }
  }

  getNameByStatus(status: string) {
    return orderStatus[status as keyof typeof orderStatus];
  }

  getTagByStatus(status: any) {
    switch (status) {
      case OrderStatus.CANCELED:
        return '#EF4444';
      case OrderStatus.CONCLUDED:
        return '#5A5A59';
      case OrderStatus.INVOICED:
        return '#57BCEB';
      case OrderStatus.ON_EXPEDITION:
        return '#0745A3';
      case OrderStatus.PAID_WAITING_DELIVERY:
        return '#008000';
      case OrderStatus.PAID_WAITING_SHIP:
        return '#000000';
      case OrderStatus.PENDING:
        return '#FFA500';
      default:
        return '';
    }
  }
}
