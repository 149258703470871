import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BudgetData } from '../../pages/cart/cart.component';
import Budget from '../../shared/models/budget/budget';
import Address from '../../shared/models/user/address';
import User from '../../shared/models/user/user';
import { AppInfoService } from '../app-info/app-info.service';
import { HttpService } from '../http/http.service';
import { CartService } from './../cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  private cartService: CartService | undefined;

  constructor(
    private injector: Injector,
    private httpService: HttpService,
    private appInfoService: AppInfoService
  ) {}

  private getCartService(): CartService {
    if (!this.cartService) {
      this.cartService = this.injector.get(CartService);
    }
    return this.cartService;
  }

  public save(budget: BudgetData): Promise<Budget> {
    const itens = budget?.cartProducts?.map((item) => ({
      amount: item.amount,
      idProduct: item.idProduct,
      controlSide: item?.controlSide,
      drive: item?.drive,
      panel: item?.panel,
      typeFixing: item?.typeFixing,
      customHeight: item?.customHeight,
      customWidth: item?.customWidth,
    }));
    return new Promise((resolve, reject) => {
      this.httpService
        .post(`budget/`, {
          email: environment.emailRM,
          name: budget.phone,
          phone: budget.phone,
          zipCode: budget.zipCode,
          cart: { cartProducts: itens },
        })
        .subscribe({
          next: (res: any) => {
            if (res) {
              resolve(Object.assign(new Budget(), res));
            }
          },
          error: (error: any) => {
            const msg =
              error?.error?.message ||
              error?.error?.error ||
              'Ocorreu um erro ao salvar o cupom!';
            reject(msg);
          },
        });
    });
  }

  public get(hash: string): Promise<Budget> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`budget/${hash}`).subscribe({
        next: (res: any) => {
          let newBudget: Budget;
          if (res) {
            newBudget = Object.assign(new Object(), res);
            const user: User = new User();
            user.fromJson(newBudget.user);
            newBudget.user = user;

            newBudget.user.address = [];
            let newAddress: Address = new Address();
            newAddress.fromJson(newBudget?.address);
            newBudget.address = newAddress;
            newBudget.carrierDescription =
              this.appInfoService.getCarrierDescription(
                newBudget.carrier || ''
              );

            if (newBudget?.cart) {
              newBudget.cart.cartProducts = this.getCartService().parseCartItem(
                newBudget?.cart?.cartProducts || []
              );

              newBudget.cart.discountSeller = newBudget.discountSeller;
              newBudget.cart.valueFreight = newBudget.valueFreight;
            }

            resolve(newBudget);
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o cupom!';
          reject(msg);
        },
      });
    });
  }

  public getMock(): Promise<Budget> {
    return new Promise((resolve) => {
      const budget: Budget = Object.assign(
        new Budget(),
        JSON.parse(this.getJson())
      );

      if (budget.user) {
        budget.user = Object.assign(new User(), budget.user);
      }

      if (budget.cart) {
        budget.cart.cartProducts = this.getCartService().parseCartItem(
          budget?.cart?.cartProducts || []
        );

        budget.cart.discountSeller = budget.discountSeller;
        budget.cart.valueFreight = budget.valueFreight;
      }

      resolve(budget);
    });
  }

  getJson() {
    return `{
      "id": 54356,
      "carrier": "DESTAK TRANSPORTES",
      "valueFreight": 120,
      "volume": 3,
      "deliveryTime": 10,
      "discountSeller": 5,
      "observations": "TESTE OBSERVAÇÕES",
      "creatorUser": {
        "acceptNewsletter": true,
        "isAdmin": true,
        "isCompany": false,
        "roles": [
          {
            "name": "ROLE_ADMIN"
          }
        ],
        "id": 34234,
        "name": "Karoline",
        "document": "00966081960",
        "phone": "48984687238",
        "birthDate": "24/12/1987",
        "email": "brunocordovaa@gmail.com",
        "lastName": "Godoi",
        "createdAt": "13/11/2020 20:03:32",
        "fullName": "Karoline Godoi"
      },
      "user": {
        "acceptNewsletter": true,
        "isAdmin": false,
        "isCompany": false,
        "roles": [
          {
            "name": "ROLE_USER"
          }
        ],
        "id": 50,
        "name": "Bruno Advaldo",
        "document": "00966081960",
        "phone": "48984687238",
        "birthDate": "24/12/1987",
        "email": "brunocordovaa@gmail.com",
        "lastName": "Córdova de Souza",
        "createdAt": "13/11/2020 20:03:32",
        "fullName": "Bruno Advaldo Córdova de Souza",
        "address": [
          {
            "id": 1263,
            "uf": "SC",
            "city": "Florianópolis",
            "district": "Balneário",
            "address": "Rua Coronel Pedro Demoro",
            "numberAddress": "1507",
            "zipCode": "88075-301",
            "complement": "602 T2",
            "principal": true
          }
        ],
        "type": "Cliente",
        "firstAddress": {
          "id": 1263,
          "uf": "SC",
          "city": "Florianópolis",
          "district": "Balneário",
          "address": "Rua Coronel Pedro Demoro",
          "numberAddress": "1507",
          "zipCode": "88075-301",
          "complement": "602 T2",
          "principal": true
        }
      },
      "cart": {
        "items": [
          {
            "idProduct": 20193,
            "amount": 1,
            "product": {
              "activeOnHub": false,
              "attachmentsCreateOrUpdate": [],
              "attachmentsDelete": [],
              "canBeGeneratedWithStockZero": true,
              "enabled": true,
              "productPerSquareMeter": true,
              "qtd": 1,
              "stockBalance": 100,
              "id": 20193,
              "productType": 1,
              "name": "Persiana Romana Napolis Blackout Mescla - valor m2",
              "category": "PERSIANAS",
              "categoryDescription": "Persianas",
              "subCategory": "ROMANA",
              "subCategoryDescription": "Romana",
              "color": "MESCLA",
              "colorDescription": "Mescla",
              "finish": "BLACKOUT",
              "width": "CM_100",
              "widthDescription": "100cm",
              "length": "CM_8",
              "lengthDescription": "8cm",
              "brand": "AMBIENTA",
              "brandDescription": "Ambientá",
              "heightThickness": "CM_100",
              "heightThicknessDescription": "100cm",
              "daysOnlyOrder": 5,
              "collection": "NAPOLIS",
              "productHubDetails": {
                "activeHub": false,
                "synchronizedWithActiveHub": false,
                "synchronizedWithHub": false
              },
              "model": "BLACKOUT",
              "modelDescription": "Blackout",
              "mainPhotoS3Url": "https://d20p8exwoll05u.cloudfront.net/product/20193/attachments/1737922770878.jpeg",
              "mainVariation": false,
              "discount": 3.5,
              "value": 277,
              "lastSincronizedPluggtoHubDate": null,
              "lastSincronizedOmieDate": "21/02/2025 12:56:06",
              "lastSincronizedFreterapidoDate": "26/01/2025 17:29:03",
              "groupMainProduct": false,
              "groupAttributeProduct": null,
              "finishDescription": "Blackout",
              "dimensions": "100cm - 100cm x 8cm",
              "weight": 1.5
            },
            "customHeight": 1,
            "customWidth": 1,
            "controlSide": "SIDE_RIGHT",
            "controlSideDescription": "Direito",
            "drive": "DRIVE_MANUAL",
            "driveDescription": "Manual",
            "panelDescription": ""
          },
          {
            "idProduct": 20177,
            "amount": 1,
            "product": {
              "activeOnHub": false,
              "attachmentsCreateOrUpdate": [],
              "attachmentsDelete": [],
              "canBeGeneratedWithStockZero": true,
              "enabled": true,
              "productPerSquareMeter": true,
              "qtd": 1,
              "stockBalance": 100,
              "id": 20177,
              "productType": 1,
              "name": "Persiana Rolo Stige Translucida Off White - valor m2",
              "category": "PERSIANAS",
              "categoryDescription": "Persianas",
              "subCategory": "ROLO",
              "subCategoryDescription": "Rolo",
              "color": "OFFWHITE",
              "colorDescription": "Offwhite",
              "finish": "TRANSLUCIDO",
              "width": "CM_100",
              "widthDescription": "100cm",
              "length": "CM_15",
              "lengthDescription": "15cm",
              "brand": "AMBIENTA",
              "brandDescription": "Ambientá",
              "heightThickness": "CM_100",
              "heightThicknessDescription": "100cm",
              "daysOnlyOrder": 4,
              "collection": "STIGE",
              "productHubDetails": {
                "activeHub": false,
                "synchronizedWithActiveHub": false,
                "synchronizedWithHub": false
              },
              "model": "TRANSLUCIDO",
              "modelDescription": "Translúcido",
              "mainPhotoS3Url": "https://d20p8exwoll05u.cloudfront.net/product/20177/attachments/1737912494137.jpeg",
              "mainVariation": false,
              "discount": 3.5,
              "value": 192,
              "lastSincronizedPluggtoHubDate": null,
              "lastSincronizedOmieDate": "13/02/2025 08:49:23",
              "lastSincronizedFreterapidoDate": "26/01/2025 14:58:24",
              "groupMainProduct": false,
              "groupAttributeProduct": null,
              "finishDescription": "Translúcido",
              "dimensions": "100cm - 100cm x 15cm",
              "weight": 1.1
            },
            "customHeight": 1.6,
            "customWidth": 1.62,
            "controlSide": "SIDE_LEFT",
            "controlSideDescription": "Esquerdo",
            "drive": "DRIVE_MOTOR",
            "driveDescription": "Motorizado(R$ 863,00)",
            "panel": "WITH_PANEL",
            "panelDescription": "Com Bandô(R$ 124,74)"
          }
        ]
      }
    }
    `;
  }
}
