<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<header class="header-section d-lg-block d-none">
  <div class="header-top mobile-invisible">
    <div class="container">
      <p>
        Telefone e Whatsapp (48) 3369-7157 | Atendimento Segunda à Sexta: 8h00
        às 11:30 - 13:30 às 17:30
      </p>
    </div>
  </div>

  <div class="header-center mobile-invisible">
    <div class="container">
      <div class="header-center-main">
        <div class="header-logo">
          <a routerLink="/"
            ><img
              width="200"
              height="80"
              src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo.png"
              loading="lazy"
              alt="Logo Principal RM Policarbonatos e Acessórios"
          /></a>
        </div>
        <div class="header-search">
          <form>
            <div class="header-search-box">
              <p-inputGroup>
                <input
                  type="search"
                  placeholder="Encontre seu produto..."
                  required
                  pInputText
                  aria-label="Encontre seu produto..."
                  name="querySearch"
                  [(ngModel)]="querySearch"
                  (keydown.enter)="onClickSearch()"
                />
                <p-inputGroupAddon *ngIf="isCategoryPage">
                  <p-checkbox
                    name="categoryOnly"
                    [(ngModel)]="searchInCategory"
                    inputId="categoryOnly"
                    binary="true"
                    label="Somente em {{ labelCategory }}"
                  ></p-checkbox>
                </p-inputGroupAddon>
                <p-button
                  icon="pi pi-search"
                  (click)="onClickSearch()"
                  label=" "
                  ariaLabel="Encontre seu produto..."
                ></p-button>
              </p-inputGroup>
            </div>
          </form>
        </div>
        <div class="header-cart">
          <a
            routerLink="/login"
            aria-label="Faça seu login e acompanhe seus pedidos"
          >
            <i class="pi pi-user" style="font-size: 1.5rem"></i>
          </a>
          <a
            routerLink="carrinho"
            (click)="
              onClickOpenCart(); showCartHeader = false; $event.preventDefault()
            "
            [attr.aria-label]="
              '(' + countItensCart + ') itens no carrinho. Veja seus produtos'
            "
            [ngClass]="{
              'cart-disabled': countItensCart === 0
            }"
          >
            <i
              class="pi pi-shopping-cart"
              (mouseenter)="showCartHeader = true"
              badge="0"
              style="font-size: 1.5rem"
              ><span>{{ countItensCart }}</span></i
            >
          </a>
          <app-cart-header
            *ngIf="showCartHeader"
            (mouseleave)="showCartHeader = false"
            [itensCart]="itensCart"
          >
          </app-cart-header>
        </div>
      </div>
    </div>
  </div>
  <div
    class="header-menu-bar"
    [ngClass]="{
      'header-menu-bar-fixed ': scrollTop > 100
    }"
  >
    <app-menu-bar></app-menu-bar>

    <div class="discount-coupon" (click)="showCoupon = !showCoupon">
      7% OFF<span>cupom </span>7PRIMEIRACOMPRA
    </div>
    <app-coupon-home
      *ngIf="showCoupon"
      (onClickbtnClose)="showCoupon = false"
    ></app-coupon-home>

    <div class="header-logo mobile-visible">
      <a routerLink="/"
        ><img
          width="130"
          height="50"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo.png"
          alt="Logo Principal RM Policarbonatos e Acessórios - Mobile"
      /></a>
    </div>
    <div class="header-buttons mobile-visible">
      <a
        routerLink="/login"
        aria-label="Faça seu login e acompanhe seus pedidos"
      >
        <i class="pi pi-user" style="font-size: 1.5rem"></i>
      </a>
      <a
        routerLink="carrinho"
        (click)="
          onClickOpenCart(); showCartHeader = false; $event.preventDefault()
        "
        [attr.aria-label]="
          '(' + countItensCart + ') itens no carrinho. Veja seus produtos'
        "
      >
        <i class="pi pi-shopping-cart" badge="0" style="font-size: 1.5rem"
          ><span>{{ countItensCart }}</span></i
        >
      </a>
      <i
        class="pi pi-search"
        (click)="onClickShowSearch()"
        style="font-size: 1.5rem"
      ></i>
    </div>
  </div>
</header>

<div class="header-search" *ngIf="showSearch">
  <form>
    <div class="header-search-box">
      <input
        class="default-search-style-input-box border-around border-right-none"
        type="search"
        placeholder="Busque por persianas, chapas, toldos, kits..."
        required
        aria-label="Busque por persianas, chapas, toldos, kits..."
        name="querySearch"
        [(ngModel)]="querySearch"
      />
      <p-button
        icon="pi pi-search"
        (click)="onClickSearch()"
        label=" "
        ariaLabel="Busque por persianas, chapas, toldos, kits..."
      ></p-button>
    </div>
  </form>
</div>
<div class="discount-coupon" (click)="showCoupon = !showCoupon">
  7% OFF<span>cupom </span>7PRIMEIRACOMPRA
</div>
