export class ArrayUtils {
  static sort(array: Array<any>, property: string, desc = false) {
    if (this.isNotEmpty(array)) {
      array.sort((left, right) => {
        if (desc) {
          if (left[property] < right[property]) {
            return 1;
          }
          if (left[property] > right[property]) {
            return -1;
          }
        } else {
          if (left[property] < right[property]) {
            return -1;
          }
          if (left[property] > right[property]) {
            return 1;
          }
        }
        return 0;
      });
    }
  }

  static sortByField<T>(array: T[], field: keyof T): T[] {
    return array.sort((a, b) => {
      const valueA = a[field]?.toString().toLowerCase() || '';
      const valueB = b[field]?.toString().toLowerCase() || '';
      return valueA.localeCompare(valueB);
    });
  }

  /**
   * Determines whether not empty is
   *
   * @author Vinícius
   * @returns true if not empty
   *
   */
  static isNotEmpty(array: Array<any>): boolean {
    return Array.isArray(array) && array.length > 0;
  }
}
