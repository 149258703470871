<div class="products-details-main">
  <div class="container">
    <div class="products-details-main-content">
      <h2 class="product-unavailable mobile-visible" *ngIf="!_product?.enabled">
        PRODUTO INDISPONÍVEL NO MOMENTO!
      </h2>
      <div
        class="products-details-main-content-product"
        [ngClass]="{
          'products-main-unavailable': !_product?.enabled
        }"
      >
        <div class="products-galery">
          <app-galleria
            [images]="productsPhotos || []"
            [discount]="_product?.discount || 0"
          ></app-galleria>
        </div>
        <div class="products-infos" *ngIf="!_product?.productPerSquareMeter">
          <h2>{{ _product?.name }}</h2>

          <h3
            class="products-infos-interest-discount"
            *ngIf="productValuesDetails.discount && _product?.enabled"
          >
            de
            <span>{{ productValuesDetails.value | customCurrency }}</span> por
          </h3>
          <h3
            class="products-infos-interest-free-value"
            *ngIf="_product?.enabled"
          >
            {{ productValuesDetails.valueWithDiscount | customCurrency }}
            {{
              productValuesDetails.discount
                ? " à vista no PIX"
                : " em " + productValuesDetails.valueInterestFree
            }}
          </h3>
          <h3
            class="products-infos-installments-value"
            *ngIf="productValuesDetails.discount && _product?.enabled"
          >
            <span
              >{{ productValuesDetails.value | customCurrency }} em
              {{ productValuesDetails.valueInstallmentWithoutJuros }}</span
            >
          </h3>
          <h3
            class="products-infos-installments-value"
            *ngIf="_product?.enabled"
          >
            ou em até <span>{{ productValuesDetails.valueInstallment }}</span>
          </h3>
          <h3 class="products-infos-description">
            {{ _product?.description }} -
            <span (click)="onClickMoreInf()">Ver mais informações!</span>
          </h3>

          <h2
            class="product-unavailable mobile-invisible"
            *ngIf="!_product?.enabled"
          >
            PRODUTO INDISPONÍVEL NO MOMENTO!
          </h2>

          <app-freight-calculate
            *ngIf="_product?.enabled"
            [loadingFreight]="loadingFreight"
            [quantitySelected]="quantitySelected"
            [quotes]="quotes"
            (onSetQuantitySelected)="onSetQuantitySelected($event)"
            (onChangeZipCode)="onChangeZipCode()"
            (onZipCodeError)="onZipCodeError()"
            (onFoundAddress)="onAddressFound($event)"
          />

          <div class="products-infos-quote-error-freight" *ngIf="errorFreight">
            <p>{{ errorFreight }}</p>
          </div>
          <div
            class="products-infos-cart mobile-invisible"
            *ngIf="_product?.enabled"
          >
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-lock"
              iconPos="right"
              class="button-rm button-rm-green"
              aria-label="Acessar seu carrinho para finalizar sua compra"
              label="FINALIZAR SUA COMPRA"
              (click)="onClickAddToCart(true)"
            ></button>

            <button
              title="Adicionar produto ao carrinho"
              aria-label="Adicionar produto ao carrinho"
              class="button-rm button-rm-white"
              (click)="onClickAddToCart(false)"
            >
              ADICIONAR AO CARRINHO
            </button>
          </div>
          <div
            class="products-infos-other-options"
            *ngIf="!_product?.isGrouped() && _product?.productsColorBalance"
          >
            <p>Outras Opções:</p>
            <div class="products-infos-other-options-content">
              <a
                href="{{ item.url }}"
                *ngFor="let item of _product?.productsColorBalance"
                aria-label="Veja outras opções"
              >
                <img
                  src="{{ item?.urlFirstImage }}"
                  [alt]="item?.name"
                  width="80"
                  height="80"
              /></a>
            </div>
          </div>
        </div>

        <div class="products-infos" *ngIf="_product?.productPerSquareMeter">
          <div class="products-infos-square-meter-name">
            <h2>{{ _product?.name }}</h2>
            <h3>
              Marca: <span>{{ _product?.brandDescription }}</span> | Peso:
              <span>{{ _product?.weight }} kg/m² </span>| SKU:
              <span>{{ _product?.id }}</span>
            </h3>
          </div>
          <div class="products-square-meter" *ngIf="_product?.enabled">
            <div class="products-square-meter-title">
              <h3 *ngIf="_product?.isPersiana()">Monte sua Persiana</h3>
              <h3 *ngIf="!_product?.isPersiana()">Monte seu Toldo</h3>
              <div>
                <a
                  href="https://d20p8exwoll05u.cloudfront.net/catalogs/manual-de-persianas-rm-policarbbonatos.pdf"
                  target="_blank"
                  >MANUAL DE INSTALAÇÃO</a
                >
                <a (click)="isFullScreen = true">COMO MEDIR</a>
                <img
                  *ngIf="isFullScreen"
                  src="assets/images/products/como-medir.jpg"
                  class="full-screen-image"
                  (click)="isFullScreen = false"
                />
              </div>
            </div>

            <form>
              <div class="products-square-meter-form item-dropdown">
                <div class="products-square-meter-item">
                  <h4>1º - Selecione a largura:</h4>
                  <div>
                    <p-dropdown
                      [options]="optionsWidth"
                      [(ngModel)]="optionsWidthSelected"
                      (onChange)="onChangeOptionsWidth($event)"
                      name="optionsWidthSelected"
                      optionLabel="name"
                      placeholder="Largura:"
                      [filter]="true"
                      filterBy="name"
                    ></p-dropdown>
                    <img
                      class="img-witdh"
                      width="40"
                      height="40"
                      src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/1.webp"
                      alt="Escolha a Largura da persiana"
                    />
                  </div>
                </div>
                <div class="products-square-meter-item">
                  <h4>
                    2º - Selecione
                    {{ _product?.isPersiana() ? "a Altura:" : "o Avanço" }}
                  </h4>
                  <div>
                    <p-dropdown
                      [options]="optionsHeight"
                      [(ngModel)]="optionsHeightSelected"
                      (onChange)="onChangeOptionsHeight($event)"
                      name="optionsHeightSelected"
                      optionLabel="name"
                      placeholder="{{
                        _product?.isPersiana() ? 'Altura:' : 'Avanço'
                      }}"
                      [filter]="true"
                      filterBy="name"
                    ></p-dropdown>
                    <img
                      class="img-heigh"
                      width="40"
                      height="40"
                      src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/2.webp"
                      alt="{{
                        _product?.isPersiana()
                          ? 'Escolha a altura:'
                          : 'Escolha o avanço'
                      }}"
                    />
                  </div>
                </div>
              </div>

              <div
                class="products-square-meter-form"
                *ngIf="_product?.isPersiana()"
              >
                <div class="products-square-meter-item">
                  <h4>
                    {{ _product?.isPersianaRomana() ? "3º" : "1º" }} - Selecione
                    o lado do Comando:
                  </h4>
                  <div
                    class="products-square-meter-itens"
                    [ngClass]="{
                      'error-options':
                        validPersianas &&
                        !optionsBlinds.controlSideLeft &&
                        !optionsBlinds.controlSideRight
                    }"
                  >
                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.controlSideLeft
                      }"
                      (click)="
                        optionsBlinds.controlSideLeft =
                          !optionsBlinds.controlSideLeft;
                        optionsBlinds.controlSideRight = false
                      "
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/3.webp"
                        alt="Selecione o lado esquerdo"
                      />
                      <p>Esquerdo</p>
                    </div>

                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.controlSideRight
                      }"
                      (click)="
                        optionsBlinds.controlSideRight =
                          !optionsBlinds.controlSideRight;
                        optionsBlinds.controlSideLeft = false
                      "
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/4.webp"
                        alt="Selecione o lado direito"
                      />
                      <p>Direito</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="products-square-meter-form"
                *ngIf="_product?.isPersiana()"
              >
                <div class="products-square-meter-item">
                  <h4>
                    {{ _product?.isPersianaRomana() ? "4º" : "2º" }} - Selecione
                    o acionamento:
                  </h4>
                  <div
                    class="products-square-meter-itens"
                    [ngClass]="{
                      'error-options':
                        validPersianas &&
                        !optionsBlinds.driveManual &&
                        !optionsBlinds.driveMotor
                    }"
                  >
                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.driveManual
                      }"
                      (click)="onClickDrive(true, false)"
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/5.webp"
                        alt="Selecione o acionamento manual"
                      />
                      <p>Manual</p>
                    </div>
                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.driveMotor
                      }"
                      (click)="onClickDrive(false, true)"
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/6.webp"
                        alt="Selecione o acionamento motorizado"
                      />
                      <p>Motor + {{ valueMotor | customCurrency }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="products-square-meter-form"
                *ngIf="_product?.isPersiana()"
              >
                <div
                  class="products-square-meter-item"
                  *ngIf="!_product?.isPersianaRomana()"
                >
                  <h4>
                    {{ _product?.productPerSquareMeter ? "5º" : "3º" }} -
                    Selecione o acabamento:
                  </h4>
                  <div
                    class="products-square-meter-itens"
                    [ngClass]="{
                      'error-options':
                        validPersianas &&
                        !optionsBlinds.withoutPanel &&
                        !optionsBlinds.withPanel
                    }"
                  >
                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.withoutPanel
                      }"
                      (click)="onClickPanel(true, false)"
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/7.webp"
                        alt="Selecione o acabamento sem bandô"
                      />
                      <p>Sem Bandô</p>
                    </div>
                    <div
                      class="products-square-meter-item"
                      [ngClass]="{
                        'products-square-meter-item-selected':
                          optionsBlinds.withPanel
                      }"
                      (click)="onClickPanel(false, true)"
                    >
                      <img
                        width="40"
                        height="40"
                        src="https://d20p8exwoll05u.cloudfront.net/assets/images/persianas/9.webp"
                        alt="Selecione o acabamento com bandô"
                      />
                      <p>Bandô +{{ getValueTotalBando() | customCurrency }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="products-square-meter-values">
              <div class="products-square-meter-values-infos">
                <h3
                  class="products-infos-interest-discount"
                  *ngIf="productValuesDetails.discount && _product?.enabled"
                >
                  de
                  <span>{{ productValuesDetails.value | customCurrency }}</span>
                  por
                </h3>
                <h3
                  class="products-infos-interest-free-value"
                  *ngIf="_product?.enabled"
                >
                  {{ productValuesDetails.valueWithDiscount | customCurrency }}
                  {{
                    productValuesDetails.discount
                      ? " à vista no PIX"
                      : " em " + productValuesDetails.valueInterestFree
                  }}
                </h3>
                <h3
                  class="products-infos-installments-value"
                  *ngIf="productValuesDetails.discount && _product?.enabled"
                >
                  <span
                    >{{ productValuesDetails.value | customCurrency }} em
                    {{
                      productValuesDetails.valueInstallmentWithoutJuros
                    }}</span
                  >
                </h3>
                <h3
                  class="products-infos-installments-value"
                  *ngIf="_product?.enabled"
                >
                  ou em até
                  <span>{{ productValuesDetails.valueInstallment }}</span>
                </h3>
              </div>

              <div class="products-square-meter-values-cart">
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-lock"
                  iconPos="right"
                  class="button-rm button-rm-green"
                  aria-label="Acessar seu carrinho para finalizar sua compra"
                  label="FINALIZAR SUA COMPRA"
                  (click)="onClickAddToCart(true)"
                ></button>

                <button
                  title="Adicionar produto ao carrinho"
                  aria-label="Adicionar produto ao carrinho"
                  class="button-rm button-rm-white"
                  (click)="onClickAddToCart(false)"
                >
                  ADICIONAR AO CARRINHO
                </button>
              </div>
            </div>

            <app-freight-calculate
              *ngIf="_product?.enabled"
              [loadingFreight]="loadingFreight"
              [quantitySelected]="quantitySelected"
              [quotes]="quotes"
              (onSetQuantitySelected)="onSetQuantitySelected($event)"
              (onChangeZipCode)="onChangeZipCode()"
              (onZipCodeError)="onZipCodeError()"
              (onFoundAddress)="onAddressFound($event)"
            />

            <h3 *ngIf="_product?.isPersiana()" class="products-infos-ambienta">
              VENDIDO E ENTREGUE POR <span>AMBIENTÁ</span>
            </h3>
          </div>
        </div>
      </div>
      <div
        class="products-details-main-content-sections"
        [ngClass]="{
          'products-main-unavailable': !_product?.enabled
        }"
      >
        <app-products-shop-sessions
          [sessions]="_product?.informationsSection || []"
        ></app-products-shop-sessions>
      </div>
      <div class="products-details-main-content-more">
        <div class="products-more-title">Veja também</div>
        <div class="products-more-content">
          <app-carousel-products
            [products]="productsMore"
          ></app-carousel-products>
        </div>
      </div>

      <div
        class="products-details-main-content-cart-mobile mobile-visible"
        *ngIf="_product?.enabled"
      >
        <p-button
          class="prime-button-rm"
          label="ADICIONAR AO CARRINHO"
          icon="pi pi-shopping-cart"
          iconPos="right"
          aria-label="Adicionar produto ao carrinho"
          (click)="onClickAddToCart(false)"
          [disabled]="errorFreight !== ''"
        ></p-button>
      </div>
    </div>
  </div>
</div>
<app-faq [subCategory]="subCategorySelected"></app-faq>
<app-products-shop-similar
  [similarProduct]="similarProduct"
  (onClickbtnClose)="showSimilarProduct = false"
  *ngIf="showSimilarProduct"
></app-products-shop-similar>
