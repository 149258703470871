import Address from './address';

export default class User {
  public static ROLE_ADMIN = 'ROLE_ADMIN';
  public static ROLE_USER = 'ROLE_USER';

  acceptNewsletter = false;
  address?: Array<Address>;
  birthDate?: string;
  confirmPassword?: string;
  createdAt?: string;
  document?: string;
  email?: string;
  firstAddress?: Address;
  fullName?: string;
  id?: number;
  isAdmin = false;
  isCompany = false;
  lastName?: string;
  name?: string;
  password?: string;
  phone?: string;
  roles?: Array<any> = [];
  type?: string;

  fromJson(user: any) {
    if (user) {
      this.id = user?.id;
      this.name = this.formatNameString(user?.name) || '';
      this.isCompany = user?.isCompany;
      this.document = user?.document || '';
      this.phone = this.formatPhone(user?.phone) || '';
      this.birthDate = user?.birthDate || '';
      this.email = user?.email || '';
      this.password = user?.password || '';
      this.lastName = this.formatNameString(user?.lastName) || '';
      this.createdAt = user?.createdAt || '';
      this.acceptNewsletter = user?.acceptNewsletter;
      this.fullName =
        this.formatNameString(user?.fullName) || this.getFullName();

      let newAddress: Address;
      this.address = [];
      user?.address?.forEach((adress: any) => {
        newAddress = new Address();
        newAddress.fromJson(adress);
        this.address?.push(newAddress);
      });

      this.roles = [];
      user?.roles?.forEach((role: any) => {
        if (role.name === User.ROLE_ADMIN) {
          this.isAdmin = true;
        }
        this.roles?.push(role);
      });
      this.type = this.isAdmin ? 'Admin' : 'Cliente';
      this.firstAddress = this.getAddress(true);
    }
  }

  getIsAdmin(): boolean {
    const roleAdmin = this.roles?.find((role) => role.name === User.ROLE_ADMIN);
    return roleAdmin != null;
  }

  formatPhone(phone: string): string {
    if (!phone) return '';

    const digits = phone.replace(/\D/g, '');

    if (digits.length === 11) {
      return digits.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    }

    if (digits.length === 10) {
      return digits.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    }

    return phone;
  }

  getFullName(): string {
    const name = this.name?.trim();
    const lastName = this.lastName?.trim();
    return `${name} ${lastName}`;
  }

  getAddress(principal: boolean): Address {
    if ((this.address?.length || 0) > 1) {
      return this.address ? this.address[0] : new Address();
    } else {
      return (
        this.address?.find((ad) => ad.principal === principal) || new Address()
      );
    }
  }

  getFirstAddress() {
    if (this.address && this.address?.length > 0) {
      return this.address[0];
    } else {
      return null;
    }
  }

  formatNameString(value: string): string {
    if (!value) return '';

    return value
      .trim()
      .split(/\s+/)
      .map((word) =>
        word.length <= 2
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  }

  formatUserFullName(user: User) {
    if (!user || !user.name) return;

    const words = user.name.trim().split(/\s+/);

    const formatWord = (word: string): string => {
      return word.length <= 2
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    user.name = formatWord(words[0]);
    user.lastName = words.slice(1).map(formatWord).join(' ');
  }
}
