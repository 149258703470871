<swiper-container
  autoplay-delay="3000"
  autoplay-pause-on-mouse-enter="true"
  [breakpoints]="breakpoints"
  class="carousel-products-related"
>
  <swiper-slide *ngFor="let product of products">
    <div class="product-item">
      <div class="product-item-content">
        <a routerLink="{{ '/' + product.url }}">
          <img
            [src]="product.mainPhotoS3Url"
            [alt]="product.name"
            width="160"
            height="160"
            class="product-image"
          />
          <div
            *ngIf="product?.featuredBanner || (product?.discount || 0) > 0"
            class="products-item-featured"
          >
            {{ product?.featuredBanner || product?.discount + "% DE DESCONTO" }}
          </div>
          <div class="product-item-content-text">
            <div class="name">{{ product?.name }}</div>
            <div class="value">
              <p *ngIf="(product?.discount || 0) > 0" class="discount">
                de
                <span>{{ product?.value | customCurrency }}</span> por
              </p>
              <p class="total">
                {{ product?.valueWithDiscount | customCurrency }}
                <span>{{ product?.valueInterestFree }}</span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
