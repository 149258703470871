import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimeNGModule } from '../../../primeng.module';
import { CartService } from '../../../services/cart/cart.service';
import { Cart, CartItem } from '../../models/cart/cart';
import Carrying from '../../models/freight/carrying';
import FreightSelected from '../../models/freight/freight-selected';
import QuoteList from '../../models/freight/quote-list';
import Address from '../../models/user/address';
import User from '../../models/user/user';
import { SharedModule } from '../../shared.module';
import { FreightQuotesDesktopComponent } from '../freight-quotes-desktop/freight-quotes-desktop.component';
import { ZipCodeInputComponent } from '../zip-code-input/zip-code-input.component';

@Component({
  selector: 'app-cart-products-desktop',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    PrimeNGModule,
    SharedModule,
    ZipCodeInputComponent,
    FreightQuotesDesktopComponent,
  ],
  templateUrl: './cart-products-desktop.component.html',
  styleUrl: './cart-products-desktop.component.scss',
})
export class CartProductsDesktopComponent {
  @Input()
  public cart: Cart = {};

  @Input()
  public user: User | undefined;

  @Input()
  public freightSelected: FreightSelected | undefined;

  @Input()
  public loadingFreight = false;

  @Input()
  public freightError: string | undefined;

  @Input()
  public quotes?: QuoteList;

  @Output()
  public onRemoveItem: EventEmitter<CartItem> = new EventEmitter();

  @Output()
  public onSetQuantity: EventEmitter<CartItem> = new EventEmitter();

  @Output()
  public onChangeZipCode: EventEmitter<any> = new EventEmitter();

  @Output()
  public onAdressFound: EventEmitter<Address> = new EventEmitter();

  @Output()
  public onChangeFreightSelected: EventEmitter<Carrying> =
    new EventEmitter<Carrying>();

  @Output()
  public onClickCheckout: EventEmitter<any> = new EventEmitter();

  @Output()
  public onShowBudget: EventEmitter<any> = new EventEmitter();

  @Output()
  public onDeleteCart: EventEmitter<any> = new EventEmitter();

  public address?: Address;

  constructor(private cartService: CartService) {}

  removeItem(item: CartItem) {
    this.onRemoveItem.emit(item);
  }

  setQuantity(item: CartItem, add: boolean) {
    item.amount = add ? (item.amount || 0) + 1 : (item.amount || 0) - 1;
    this.onSetQuantity.emit(item);
  }

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    return this.cartService.getValueItem(cartProduct, whithDiscount);
  }

  changeZipCode() {
    this.onChangeZipCode.emit();
  }

  addressFound(address: Address) {
    this.address = address;
    this.onAdressFound.emit(address);
  }

  setFreightSelected(carrier: Carrying) {
    this.onChangeFreightSelected.emit(carrier);
  }

  clickCheckout() {
    this.onClickCheckout.emit();
  }

  onClickShowBudget() {
    this.onShowBudget.emit();
  }

  onClickDelete() {
    this.onDeleteCart.emit();
  }

  hasCart(): boolean {
    return (this?.cart?.cartProducts?.length || 0) > 0 || false;
  }
}
