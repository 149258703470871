<div class="container">
  <div class="cart-main-products-desktop">
    <div class="cart-main-products-desktop-grid">
      <h2>PRODUTOS</h2>
      <div
        class="cart-main-products-desktop-grid-item"
        *ngFor="let item of cart.cartProducts"
      >
        <div class="cart-main-products-desktop-grid-item-img">
          <a href="{{ item?.product?.url }}" target="_blank">
            <img
              [src]="
                item?.product?.mainPhotoS3Url ||
                item?.product?.photos?.slice()?.shift()?.url ||
                ''
              "
              [alt]="item?.product?.name"
              width="100"
              height="100"
            />
          </a>
          <p>
            {{ item?.product?.name }}
            {{
              (item?.product?.discount || 0) > 0
                ? " - " + item?.product?.discount + "% OFF"
                : ""
            }}
            <span *ngIf="item?.product?.productPerSquareMeter">
              {{
                item?.product?.productPerSquareMeter
                  ? "Largura: " +
                    item?.customWidth?.toFixed(2) +
                    "m x Altura: " +
                    item?.customHeight?.toFixed(2) +
                    "m"
                  : ""
              }}
            </span>

            <span *ngIf="item?.product?.isPersiana()">
              {{ item?.driveDescription }}
              | {{ item?.controlSideDescription }}
              {{
                item?.product?.isPersianaRomana()
                  ? ""
                  : " | " + item?.panelDescription
              }}
            </span>

            <span>
              {{ "Marca: " + item?.product?.brandDescription || "" }} -
              {{ "Código: " + item?.product?.id }}</span
            >
          </p>
        </div>

        <div class="cart-main-products-desktop-grid-item-qtd">
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-minus-circle"
            (click)="setQuantity(item, false)"
          ></button>
          <span>{{ item.amount }}</span>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-plus-circle"
            (click)="setQuantity(item, true)"
          ></button>
        </div>
        <div class="cart-main-products-desktop-grid-item-value">
          <p [ngClass]="{ 'with-discount': (item.product?.discount || 0) > 0 }">
            {{
              getValueItem(item, false) * (item?.amount || 0) | customCurrency
            }}
          </p>
          <p *ngIf="(item.product?.discount || 0) > 0">
            {{
              getValueItem(item, true) * (item?.amount || 0) | customCurrency
            }}
          </p>
        </div>
      </div>

      <div
        class="cart-main-products-desktop-grid-admin"
        *ngIf="user && user?.isAdmin && hasCart()"
      >
        <button
          class="button-rm"
          aria-label="Gerar orçamento para cliente"
          (click)="onClickShowBudget()"
          title="Gerar orçamento para cliente"
        >
          GERAR ORÇAMENTO
        </button>
        <button
          pButton
          pRipple
          aria-label="Remover o carrinho"
          (click)="onClickDelete()"
          title="Remover o carrinho"
          severity="contrast"
        >
          LIMPAR
        </button>
      </div>
    </div>
    <div class="cart-main-products-desktop-resume">
      <h2>RESUMO</h2>
      <div class="cart-main-products-desktop-resume-freight">
        <app-zip-code-input
          placeholder="Seu CEP"
          (changed)="changeZipCode()"
          (addressFound)="addressFound($event)"
        ></app-zip-code-input>
        <p *ngIf="address">{{ address.city + "/" + address.uf }}</p>
        <div class="cart-resume-freight-quotes">
          <app-freight-quotes-desktop
            [loadingFreight]="loadingFreight"
            [quotes]="quotes"
            [errorFreight]="freightError"
            [freightSelected]="freightSelected"
            (onChangeFreightSelected)="setFreightSelected($event)"
          ></app-freight-quotes-desktop>
        </div>
      </div>
      <div class="cart-main-products-desktop-resume-value">
        <div class="cart-main-products-desktop-resume-value-subtotal">
          <p>
            Subtotal <span>{{ cart.valueSubtotal | customCurrency }}</span>
          </p>
          <p *ngIf="cart.haveProductDiscount">
            Desconto à vista ({{
              ((cart.valueDiscount || 0) * 100) / (cart.valueSubtotal || 0)
                | number : "1.2-2"
            }}%)
            <span>-{{ cart.valueDiscount | customCurrency }}</span>
          </p>
          <p *ngIf="(freightSelected?.freight?.priceFreight || 0) > 0">
            Frete
            <span>{{
              freightSelected?.freight?.priceFreight || 0 | customCurrency
            }}</span>
          </p>
        </div>
        <div class="cart-main-products-desktop-resume-value-total">
          <p>Total</p>
          <div>
            <p>{{ cart.valueTotal || 0 | customCurrency }} à vista</p>
            <p>
              {{
                (cart.valueSubtotal || 0) +
                  (freightSelected?.freight?.priceFreight || 0) | customCurrency
              }}
              em 4x sem juros
            </p>

            <p>ou em até 12x vezes</p>
          </div>
        </div>
      </div>
      <div class="cart-main-products-desktop-resume-footer">
        <button
          (click)="clickCheckout()"
          title="Finalizar sua compra"
          class="button-rm"
          aria-label="Finalizar sua compra"
        >
          FINALIZAR SUA COMPRA
        </button>
        <a
          routerLink="/coberturas-em-policarbonato/policarbonato-alveolar"
          routerLinkActive="router-link-active"
        >
          <button
            title="Continuar comprando"
            class="button-rm"
            aria-label="Continuar comprando"
          >
            CONTINUAR COMPRANDO
          </button></a
        >
      </div>
    </div>
  </div>
  <div class="container-footer-infos">
    <a target="_blank" href="politicas-trocas-cancelamentos"
      >Trocas, Devoluções e Políticas de Entrega</a
    >
    <a target="_blank" href="termos-condicoes-uso">Termos e Condições</a>
    <a target="_blank" href="aviso-de-privacidade">Aviso de Privacidade</a>
    <a target="_blank" href="faq">Perguntas Frequentes</a>
    <a target="_blank" href="fale-conosco">Fale Conosco</a>
  </div>
</div>
