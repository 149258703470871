// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: 'rm-ecommerce',
  clientSecret: 'rmpoliauth123',
  linkCart: '/carrinho',
  maxInstallmentNoInterest: 4,
  minimumInstallment: 5,
  whatsAppMain: '554833697157',
  siteKeyGoogle: '6LdED0wqAAAAAI4H_JTTzpgTieKWY3k9AhrGuXxa',
  emailRM: 'contato@rmpolicarbonatos.com.br',
  //authURL: 'https://homolog.rmpolicarbonatos.com.br/ecommerce/oauth/token',
  //apiUrl: 'https://homolog.rmpolicarbonatos.com.br/ecommerce/',
  authURL: 'https://api.rmpolicarbonatos.com.br/ecommerce/oauth/token',
  apiUrl: 'https://api.rmpolicarbonatos.com.br/ecommerce/',
  //authURL: 'http://localhost:8080/ecommerce/oauth/token',
  //apiUrl: 'http://localhost:8080/ecommerce/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
