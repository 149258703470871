import {
  afterNextRender,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../primeng.module';
import { AuthService } from '../../services/auth/auth.service';
import { BudgetService } from '../../services/budget/budget.service';
import { CartService } from '../../services/cart/cart.service';
import { LoaderService } from '../../services/loader/loader.service';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { ToastService } from '../../services/toast/toast.service';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import Budget from '../../shared/models/budget/budget';
import User from '../../shared/models/user/user';
import { SharedModule } from '../../shared/shared.module';
import { PaymentService } from './../../services/payment/payment.service';
import { CartItem } from './../../shared/models/cart/cart';

@Component({
  selector: 'app-budget',
  standalone: true,
  imports: [BreadcrumbComponent, PrimeNGModule, SharedModule],
  templateUrl: './budget.component.html',
  styleUrl: './budget.component.scss',
  providers: [ToastService, MessageService],
})
export class BudgetComponent implements OnInit {
  public budget?: Budget;
  public isLoading$;
  public user: User | undefined = new User();

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private budgetService: BudgetService,
    private cartService: CartService,
    private metaServices: MetaTagsService,
    private paymentService: PaymentService,
    private productService: ProductsService,
    public router: Router,
    private loaderService: LoaderService,
    public sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.loaderService.show();
    this.isLoading$ = this.loaderService.isLoading$;

    this.authService.userData$.subscribe((res: any) => {
      this.user = undefined;
      if (res) {
        this.user = new User();
        this.user.fromJson(res);
      }
    });

    afterNextRender(() => {
      this.init();
    });
  }
  ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_BUDGET,
        url[0]?.path,
        '',
        '',
        null
      );
    });
  }

  async init() {
    await this.productService.getValueMotorBando();
    this.configCart();
  }

  async configCart() {
    try {
      const hashBudget =
        this.activatedRoute.snapshot.paramMap.get('hashBudget') || '';
      this.budget = await this.budgetService.get(hashBudget);
      if (this.budget.cart) {
        this.cartService.setAuxVariables(this.budget.cart, undefined);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loaderService.hide();
    }
  }

  getTotalWeight() {
    let total = 0;

    if (this.budget) {
      this.budget?.cart?.cartProducts?.forEach((item: CartItem) => {
        total += item?.product?.weight || 0;
      });
    }
    return total;
  }

  getTotalInstallment(installment: number) {
    const total =
      (this.budget?.cart?.valueSubtotal || 0) +
      (this.budget?.cart?.valueFreight || 0);
    const interest: number =
      (this.paymentService.getInterest(installment) / 100) * total;
    return interest + total;
  }

  getValueItem(cartProduct: CartItem, whithDiscount: boolean) {
    return this.cartService.getValueItem(cartProduct, whithDiscount);
  }
}
