<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb
  [path]="'Checkout Confiável'"
  [showTitle]="true"
></app-breadcrumb>

<div class="payment-main">
  <div class="container">
    <div class="payment-main-content">
      <div class="payment-main-content-address">
        <h2>CONTATO</h2>
        <div class="payment-main-content-address-contact">
          <div class="payment-main-content-address-contact-name">
            <input
              aria-label="Informe seu nome"
              placeholder="Nome"
              type="text"
              required
              name="name"
              pInputText
              [(ngModel)]="user.name"
              [ngClass]="{
                'error-input': userError.errorName
              }"
            />
            <input
              aria-label="Informe seu sobrenome"
              placeholder="Sobrenome"
              type="text"
              required
              name="lastName"
              pInputText
              [(ngModel)]="user.lastName"
              [ngClass]="{
                'error-input': userError.errorLastName
              }"
            />
          </div>

          <input
            placeholder="E-mail"
            aria-label="Informe seu e-mail"
            type="email"
            name="email"
            required
            pInputText
            [(ngModel)]="user.email"
            [ngClass]="{
              'error-input': userError.errorName
            }"
          /><p-inputMask
            (onComplete)="onCompleteDocument()"
            ariaLabel="Informe seu CPF"
            placeholder="CPF"
            mask="999.999.999-99"
            name="document"
            id="document"
            [required]="true"
            [(ngModel)]="user.document"
            [ngClass]="{
              'error-input-mask': userError.errorDocument
            }"
          ></p-inputMask>
          <p-inputMask
            ariaLabel="Informe seu telefone com DDD"
            placeholder="Telefone com DDD"
            mask="(99) 99999-9999"
            name="phone"
            id="phone"
            [required]="true"
            [(ngModel)]="user.phone"
            [ngClass]="{
              'error-input-mask': userError.errorPhone
            }"
          ></p-inputMask>
          <p-inputMask
            ariaLabel="Informe sua data de nascimento"
            placeholder="Data de nascimento"
            mask="99/99/9999"
            name="birthDate"
            id="birthDate"
            [required]="true"
            [(ngModel)]="user.birthDate"
            [ngClass]="{
              'error-input-mask': userError.errorBirthDate
            }"
          ></p-inputMask>
          <p-checkbox
            label="Quero receber novidades e ofertas por e-mail"
            [(ngModel)]="acceptNewsletter"
            [binary]="true"
          />
        </div>
        <h2>ENTREGA</h2>
        <div class="payment-main-content-address-main">
          <app-address
            [address]="address"
            [addressError]="addressError"
            [addressDisable]="addressDisable"
            [showKnowZipCoded]="false"
            [showLabel]="false"
            (clearedZip)="clearedZip()"
            (foundAddress)="foundAddress($event)"
          ></app-address>

          <app-freight-quotes-desktop
            [loadingFreight]="loadingFreight"
            [quotes]="quotes"
            [freightSelected]="cartCheckout?.freightSelected"
            (onChangeFreightSelected)="setFreightSelectedDesktop($event)"
          ></app-freight-quotes-desktop>
        </div>
      </div>
      <div class="payment-main-content-payment">
        <div class="payment-main-content-payment-methods">
          <h2>PAGAMENTO</h2>
          <div class="payment-main-content-payment-methods-pix">
            <p-checkbox
              label="Pix"
              name="paymentMethod"
              value="PIX"
              [binary]="true"
              [ngModel]="paymentSelected === 'PIX'"
              (onChange)="selectPayment('PIX')"
            />
            <img
              src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/pix-48.png"
              alt="Logo Pix"
              width="22"
              height="22"
            />
          </div>
          <div class="payment-main-content-payment-methods-card">
            <div class="payment-main-content-payment-methods-card-check">
              <p-checkbox
                label="Cartão de crédito"
                name="paymentMethod"
                value="CARTAO_CREDITO"
                [binary]="true"
                [ngModel]="paymentSelected === 'CARTAO_CREDITO'"
                (onChange)="selectPayment('CARTAO_CREDITO')"
              />
              <img
                src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/visa-48.png"
                alt="Logo Pix"
                width="32"
                height="23"
              /><img
                src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/mastercard-48.png"
                alt="Logo Pix"
                width="32"
                height="23"
              /><img
                src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/elo-48.png"
                alt="Logo Pix"
                width="32"
                height="23"
              />
              <img
                src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/amex-48.png"
                alt="Logo Pix"
                width="32"
                height="23"
              />
              <p>4+</p>
            </div>
            <app-credit-card-input
              [disabled]="paymentSelected === 'PIX'"
              [card]="card"
              [cardInputError]="cardInputError"
              [paymentMethods]="paymentMethods"
            ></app-credit-card-input>
          </div>
        </div>

        <div class="payment-main-content-payment-same-address">
          <p-checkbox
            [(ngModel)]="sameAddressForInvoice"
            [binary]="true"
            label="Usar o endereço de entrega como de faturamento"
          />
        </div>

        <div class="payment-main-content-payment-coupon">
          <input
            placeholder="Informe seu cupom"
            aria-label="Cupom desconto"
            type="text"
            name="coupon"
            pInputText
            (keydown.enter)="onClickBtcalculateCoupon()"
            [(ngModel)]="coupon"
          />
          <button
            (click)="onClickBtcalculateCoupon()"
            title="Calcular cupom desconto"
            class="button-rm"
            aria-label="Calcular cupom desconto"
          >
            USAR
          </button>
        </div>

        <div class="payment-main-content-payment-resume">
          <div class="payment-resume-totals">
            <div class="payment-resume-subtotal">
              <p>Subtotal</p>
              <p>
                {{ cartCheckout?.gross || 0 | customCurrency }}
              </p>
            </div>

            <div class="payment-resume-subtotal" *ngIf="couponDiscount">
              <p>
                Cupom: {{ couponDiscount.coupon?.code
                }}<span
                  class="payment-resume-subtotal-coupon"
                  (click)="onClickBtRemoveCoupon()"
                  >Remover</span
                >
              </p>
              <p>
                {{ couponDiscount.totalDiscount || 0 | customCurrency }}
              </p>
            </div>
            <div
              class="payment-resume-subtotal"
              *ngIf="
                haveProductDiscount() &&
                paymentSelected === 'PIX' &&
                !couponDiscount
              "
            >
              <p class="payment-resume-subtotal-discount">
                Desconto do produto
              </p>
              <p class="payment-resume-subtotal-discount">
                -
                {{ cartCheckout?.discount | customCurrency }}
              </p>
            </div>

            <div
              *ngIf="
                (cartCheckout?.freightSelected?.freight?.priceFreight || 0) > 0
              "
              class="payment-resume-subtotal"
            >
              <p>Frete</p>
              <p>
                +
                {{
                  cartCheckout?.freightSelected?.freight?.priceFreight || 0
                    | customCurrency
                }}
              </p>
            </div>
            <div class="payment-resume-total">
              <p>Total</p>
              <p>{{ getFinalValue() || 0 | customCurrency }}</p>
            </div>
          </div>
          <button
            (click)="onClickCheckout()"
            title="Realizar o pagamento e finalizar sua compra"
            class="button-rm"
            aria-label="Realizar o pagamento e finalizar sua compra"
          >
            FINALIZAR SUA COMPRA
          </button>
        </div>
      </div>
      <div class="payment-main-content-resume">
        <h2>RESUMO DA COMPRA</h2>
        <div class="payment-resume-main">
          <div class="payment-resume-products">
            <a
              href="{{ item?.product?.url }}"
              target="_blank"
              class="payment-resume-products-item"
              *ngFor="let item of cartCheckout?.cart?.cartProducts"
            >
              <div class="payment-resume-products-item-description">
                <img
                  [src]="item?.product?.photos?.slice()?.shift()?.url"
                  [alt]="item?.product?.name"
                  width="80"
                  height="80"
                />
                <div class="payment-resume-products-item-text">
                  <p>
                    {{ item?.product?.name }}
                    <span *ngIf="item?.product?.productPerSquareMeter">
                      {{
                        item?.product?.productPerSquareMeter
                          ? "Largura: " +
                            item?.customWidth?.toFixed(2) +
                            "m x Altura: " +
                            item?.customHeight?.toFixed(2) +
                            "m"
                          : ""
                      }}
                    </span>

                    <span *ngIf="item?.product?.isPersiana()">
                      {{ item?.driveDescription }}
                      | {{ item?.controlSideDescription }}
                      {{
                        item?.product?.isPersianaRomana()
                          ? ""
                          : " | " + item?.panelDescription
                      }}
                    </span>

                    <span>
                      {{ "Marca: " + item?.product?.brandDescription || "" }} -
                      {{ "Código: " + item?.product?.id }}</span
                    >
                  </p>
                  <div class="payment-resume-products-item-value">
                    <p>Quantidade: {{ item.amount }}</p>
                    <p>
                      <span *ngIf="item.discount">
                        {{ item.gross | customCurrency }}
                      </span>
                      &nbsp;&nbsp;{{ item.total | customCurrency }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="container-footer-infos">
      <a target="_blank" href="politicas-trocas-cancelamentos"
        >Trocas, Devoluções e Políticas de Entrega</a
      >
      <a target="_blank" href="termos-condicoes-uso">Termos e Condições</a>
      <a target="_blank" href="aviso-de-privacidade">Aviso de Privacidade</a>
      <a target="_blank" href="faq">Perguntas Frequentes</a>
      <a target="_blank" href="fale-conosco">Fale Conosco</a>
    </div>
  </div>
</div>
<app-modal-pix
  [order]="order"
  [pix]="pix"
  [showCountDown]="true"
  [visible]="showModalPix"
  (closeModal)="closeModalPix()"
  (paymentAccept)="userService.goToCustomerOrders()"
></app-modal-pix>
